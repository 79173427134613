<template>
  <div>
    <el-container>
      <el-header>
        <el-menu mode="horizontal" :ellipsis="false" router>
          <div class="flex-grow" />
          <el-menu-item index="0" style="margin-top: 0px;" route="/">
            <img :src="require('@/assets/logo-text.jpg')" style="width: 120px;"></img>
          </el-menu-item>
          <el-menu-item index="/">首页
          </el-menu-item>
          <el-menu-item index="/product">产品
          </el-menu-item>
          <el-menu-item index="/solution">解决方案
          </el-menu-item>
          <el-menu-item index="/about">关于我们
          </el-menu-item>
          <div class="flex-grow" />
        </el-menu>
      </el-header>
      <el-main style="overflow: hidden;">
        <router-view />
      </el-main>
      <el-footer>
        <el-row>
          <p></p>
        </el-row>
        <el-space direction="vertical">
          <el-row>
            <el-text>Copyright © 2023-2024 . 南京念达信息 版权所有</el-text>
          </el-row>
          <el-row>
            <el-link href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank">
              <img :src="require('@/assets/beian.png')" style="width: 20px;" />
              <el-text>苏公网安备32010402001558号</el-text>
            </el-link>
            <el-link href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
              <el-text>苏ICP备2023023950号-1</el-text>
            </el-link>
          </el-row>
          <el-row>
            <p></p>
          </el-row>
        </el-space>
      </el-footer>
    </el-container>
  </div>
  <el-backtop :right="100" :bottom="100">
  </el-backtop>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.el-link {
  margin-right: 8px;
}

.router-link {
  margin-right: 10px;
}

.no-underline {
  text-decoration: none;
  /* 移除下划线 */
}

.flex-grow {
  flex-grow: 1;
}

.el-main {
  padding: 0px !important
}

.el-footer {
  padding: 0px !important
}

.el-menu-item.is-active {
  background-color: transparent;
}
</style>
